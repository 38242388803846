import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import api from "../../API/api";

import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const navigation = useNavigate();
  const [count, setCount] = useState();
  const [service, setService] = useState([]);
  const [all, setAll] = useState([]);

  useEffect(() => {
    getVisitors();
  }, []);

  const getVisitors = () => {
    api.getVisitors().then((res) => {
      setCount(res.data.count);
      setService(res.data.services);
      setAll(res.data.all);
    });
  };

  return (
    <>
      <div className="containe">
        <div className="row">
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/doctor/appointments")}
            >
              <div className="p-3">
                <h5> New Doctor Appointments : {count && count.tot_appoint}</h5>
              </div>
            </Paper>
          </div>
          {/* <div className="col-4 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Departments : {count && count.tot_depart }</h5>
                            </div>
                        </Paper>
                    </div> */}
          {/* <div className="col-4 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Doctors : {count && count.tot_doc}</h5>
                            </div>
                        </Paper>
                    </div> */}
          <div className="col-4 p-2">
            <Paper elevation={3} onClick={() => navigation("/admin/CallLeads")}>
              <div className="p-3">
                <h5>Call Now Button Clicked : {count && count.tot_callUs}</h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper elevation={3}>
              <div className="p-3">
                <h5>Total Visitors : {count && count.tot_visitors}</h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/health-package/appointments")}
            >
              <div className="p-3">
                <h5>
                  Health Check Appointments :{" "}
                  {count && count.health_appointment}
                </h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/service/appointments")}
            >
              <div className="p-3">
                <h5>
                  Home Sample Appointments :{" "}
                  {service && service["home-blood-collection"]}
                </h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/diagnostic/appointments")}
            >
              <div className="p-3">
                <h5>
                  Diagnostic Services :{" "}
                  {/* {service && service["diagnostic-service"]} */}
                  {count && count.tot_diag}

                </h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/service/appointments")}
            >
              <div className="p-3">
                <h5>All Services Requested : {all && all}</h5>
              </div>
            </Paper>
          </div>
          <div className="col-4 p-2">
            <Paper
              elevation={3}
              onClick={() => navigation("/admin/pharmacy/orders")}
            >
              <div className="p-3">
                <h5>Pharmacy : {service && service.pharmacy}</h5>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
