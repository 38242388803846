import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Select,
  MenuItem,
  Button,
  Box,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import api from "../../API/api";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import DebounceSpinner from "../../components/common/debounce-spinner";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"; // Import Dialog components
import { Login } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const BannerHomePage = () => {
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [banner, setBanner] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal open/close
  const [imageLink, setImageLink] = useState("");
  const [modalData, setModalData] = useState("");

  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setImagePreview(URL.createObjectURL(e[0]));
    setImage(e[0]);
    console.log(e[0], "dasd");
    // console.log(banner,'banner');
  };

  const handleupload = () => {
    setLoading(true);
    const formdata = new FormData();
    if (banner?.id) {
      formdata.append("id", banner.id);
    }
    formdata.append("image", image);
    formdata.append("banner_for", "home_page");
    formdata.append("priority", bannerImages.length + 1);

    api
      .uploadBannerSingle(formdata)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          fetchbanenr();
          Swal.fire({
            title: "Success!",
            text: "Added Succeffully !",
            icon: "success",
            confirmButtonText: "Ok",
          });
          setImagePreview("");
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function fetchbanenr() {
    setLoading(true);
    api
      .getBanners("home_page")
      .then((res) => {
        console.log(res, "banner");
        setBanner(res.data.banner);
        setBannerImages(res.data.images);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }
  const handleOpenLink = (data) => {
    setOpenModal(!openModal);
    setModalData(data);
  };

  useEffect(() => {
    fetchbanenr();
  }, []);

  const handlePriorityChange = (imageId, newPriority) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("banner_image_id", imageId);
    formData.append("banner_for", "home_page");
    formData.append("priority", newPriority);
    api
      .updateBannerPriority(formData)
      .then((res) => {
        if (res.data.status === 200) {
          fetchbanenr(); // Refresh the banner images
          Swal.fire({
            title: "Success!",
            text: "Priority updated successfully!",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          title: "Error!",
          text: "Failed to update priority",
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    setLoading(true);
    api
      .deleteBannerSingle({ banner_image_id: id, banner_for: "home_page" })
      .then((res) => {
        console.log(res);
        fetchbanenr();
        Swal.fire({
          title: "Success!",
          text: "Deleted Successfully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleImageLinkSubmit = () => {
    // Logic to handle the image link submission
    const payload = { id: modalData.id, link: imageLink };

    setLoading(true);
    api
      .addLinkSingle(payload)
      .then((res) => {
        console.log(res);
        fetchbanenr();
        setOpenModal(!openModal);
        Swal.fire({
          title: "Success!",
          text: "Link Added Successfully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        // console.log(err);
        if (err.response && err.response.data && err.response.data.errors) {
          const errorMessage = err.response.data.errors.link.join(", ");
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          // console.log(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // console.log("Image Link Submitted:", imageLink);
    // handleCloseModal(); // Close the modal after submission
  };

  return (
    <div className="row">
      <Typography variant="h6">Banner For Home Page</Typography>
      {loading && <DebounceSpinner isDebouncing={loading} />}
      {!loading && (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ overflowX: "auto", pb: 2 }}
            flexWrap={"wrap"}
            gap={2}
            rowGap={2}
          >
            <Card
              sx={{
                width: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imagePreview && (
                <Stack direction="column" spacing={2} py={2}>
                  <CardMedia
                    component="img"
                    height="140"
                    sx={{ objectFit: "contain" }}
                    image={imagePreview}
                    alt={`New Banner`}
                  />
                  <Button onClick={() => setImagePreview("")}>Remove</Button>
                </Stack>
              )}
              {imagePreview && (
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{}}
                  onClick={handleupload}
                >
                  Upload Image
                  {/* <VisuallyHiddenInput
           type="file"
           onChange={(event) => handleInput(event.target.files)}
         /> */}
                </Button>
              )}
              {!imagePreview && (
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{}}
                >
                  Add Image
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleInput(event.target.files)}
                  />
                </Button>
              )}
            </Card>
            {bannerImages &&
              bannerImages.length > 0 &&
              bannerImages.map((image, index) => (
                <Card
                  key={index}
                  sx={{ width: 500, display: "flex", flexDirection: "column" }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    sx={{ objectFit: "contain" }}
                    image={`${process.env.REACT_APP_BACKEND}assets/banners/home_page/${image.image}`}
                    alt={`Banner ${index + 1}`}
                  />
                  <CardContent>
                    <Stack direction="row" spacing={2}>
                      <Select
                        value={image.priority}
                        onChange={(e) =>
                          handlePriorityChange(image.id, e.target.value)
                        }
                        // fullWidth
                        size="small"
                      >
                        {Array.from(
                          { length: bannerImages.length },
                          (_, index) => index + 1
                        ).map((priority) => (
                          <MenuItem key={priority} value={priority}>
                            Priority {priority}
                          </MenuItem>
                        ))}
                      </Select>

                      <Button
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />} // Add the Delete icon
                        onClick={() => handleOpenLink(image)} // Call a delete function
                      >
                        Add a Link
                      </Button>
                      <Button
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />} // Add the Delete icon
                        onClick={() => handleDelete(image.id)} // Call a delete function
                      >
                        Delete
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
          </Stack>
          <Dialog open={openModal} onClose={() => setOpenModal(!openModal)}>
            <DialogTitle>Add Image Link</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Image Link"
                type="text"
                fullWidth
                variant="outlined"
                value={imageLink}
                onChange={(e) => setImageLink(e.target.value)} // Update image link state
              />
              <small>
                *Link must be like :-https://apolloclinicguwahati.co.in with https://
              </small>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(!openModal)}>Cancel</Button>
              <Button onClick={handleImageLinkSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      )}
    </div>
  );
};
